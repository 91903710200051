import { api } from '.';
import { CLIENT_RESOURCE, FILTER_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ThirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @param {string} type
 * @returns {Promise<any>}
 */
export async function getFilterByType(clientId, type, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [CLIENT_RESOURCE]: clientId,
    [FILTER_RESOURCE]: type,
  });
  params.addSort(type);
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ThirdPartyEntityParser.parse(data)?.options || [], data.meta).build();
}
