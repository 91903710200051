import { getFilterByType } from '@/services/modules/ThirdParty/filters/getFilterByType';

/**
 *
 * @param {string} clientId
 * @param {import('@/model/shared/QueryParamsBuilder').default} params
 * @returns {Promise<any>}
 */
export function getAdOpsFilterOptions(clientId, params) {
  return getFilterByType(clientId, 'adopsName', params);
}
