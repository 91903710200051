import { SALESFORCE_OPPORTUNITIES_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { api } from '.';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = opportunityId => ({
  [SALESFORCE_OPPORTUNITIES_RESOURCE]: opportunityId,
});

export async function getSalesforceOpportunitiesByIdShared(parser, params = new QueryParamsBuilder(), opportunityId) {
  if (!parser) throw new Error('Parser not provided');
  const partialUrl = api.createUrl(getResources(opportunityId));
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(parser.parse(data), data.meta).build();
}
