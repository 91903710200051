import Filter from '@/model/shared/Filter';
import SelectOption from '@/model/shared/SelectOption';
import { getAdvertisersForFilter, getAdvertiserById } from '@/services/modules/ThirdParty/advertiser';
// import { getConnectedPlatforms } from '@/services/modules/ThirdParty/platforms';
import {
  // getAccountFilterOptions,
  getAccountManagerFilterOptions,
  getAdOpsFilterOptions,
  getFormatFilterOptions,
  getOfficeFilterOptions,
  getOwnerFilterOptions,
  getRateFilterOptions,
  getKpiFilterOptions,
  getRecordTypeFilterOptions,
} from '@/services/modules/ThirdParty/filters';
import { getPlatforms, getPlatformsById } from '@/services/modules/ThirdParty/platforms';
import { getSalesforceProduct, getSalesforceProductList } from '@/services/modules/ThirdParty/product';
import { getSalesforceOpportunities } from '@/services/modules/ThirdParty/salesforceOpportunity/getSalesforceOpportunities';
import { getSalesforceOpportunitiesById } from '@/services/modules/ThirdParty/salesforceOpportunity/getSalesforceOpportunitiesById';
import { BREAKDOWN_VALUES } from './analytics/BreakdownValues';

const getFilterByType = async item => new SelectOption(item, item);

export const FILTERS_KEYS = {
  AD_OPS: 'AD_OPS',
  ADVERTISER_ID: 'ADVERTISER_ID',
  ADVERTISER_NAME: 'ADVERTISER_NAME',
  PLATFORM_ID: 'PLATFORM_ID',
  PRODUCT_ACCOUNT_MANAGER: 'PRODUCT_ACCOUNT_MANAGER',
  PRODUCT_ACCOUNT_NAME: 'PRODUCT_ACCOUNT_NAME',
  PRODUCT_ADOPS_NAME: 'PRODUCT_ADOPS_NAME',
  PRODUCT_LINE_ITEM_ID: 'PRODUCT_LINE_ITEM_ID',
  PRODUCT_NAME: 'PRODUCT_NAME',
  PRODUCT_OFFICE: 'PRODUCT_OFFICE',
  PRODUCT_OWNER_NAME: 'PRODUCT_OWNER_NAME',
  PRODUCT_RATE: 'PRODUCT_RATE',
  PRODUCT_KPI: 'PRODUCT_KPI',
  PRODUCT_REPORT_ID: 'PRODUCT_REPORT_ID',
  PRODUCT_FORMAT: 'PRODUCT_FORMAT',
  PRODUCT_RECORDTYPE: 'PRODUCT_RECORDTYPE',
  PRODUCT_OWNER: 'PRODUCT_OWNER',
  PRODUCT_ADOPS: 'PRODUCT_ADOPS',
  PRODUCT_TAG: 'PRODUCT_TAG',
  PRODUCT_ID: 'PRODUCT_ID',
  CAMPAIGN_NAME: 'CAMPAIGN_NAME',
  CAMPAIGN_GROUP_NAME: 'CAMPAIGN_GROUP_NAME',
  CAMPAIGN_GROUP_STATUS: 'CAMPAIGN_GROUP_STATUS',
  CAMPAIGN_GROUP_SALESFORCE_ID: 'CAMPAIGN_GROUP_SALESFORCE_ID',
  CAMPAIGN_ID: 'CAMPAIGN_ID',
};

export default {
  PLATFORM_ID: new Filter(FILTERS_KEYS.PLATFORM_ID, 'Platform', 'platform.id')
    .setBreakdown(BREAKDOWN_VALUES.campaign, BREAKDOWN_VALUES.platform)
    .setOptions(params => getPlatforms(params))
    .getItemBy(getPlatformsById),
  CAMPAIGN_ID: new Filter(FILTERS_KEYS.CAMPAIGN_ID, 'Campaign', 'campaign.id')
    .setBreakdown(BREAKDOWN_VALUES.campaign)
    .setOptions(params => getSalesforceOpportunities(params))
    .getItemBy(getSalesforceOpportunitiesById),
  PRODUCT_ID: new Filter(FILTERS_KEYS.PRODUCT_ID, 'Product', 'product.id')
    .getItemBy(getSalesforceProduct)
    .setOptions(params => getSalesforceProductList(params)),
  ADVERTISER_NAME: new Filter(FILTERS_KEYS.ADVERTISER_NAME, 'Name', 'advertiser.name'),
  ADVERTISER_ID: new Filter(FILTERS_KEYS.ADVERTISER_ID, 'Advertiser', 'advertiser.id')
    .asMultiple()
    .setOptions(getAdvertisersForFilter)
    .getItemBy(getAdvertiserById),
  AD_OPS: new Filter(FILTERS_KEYS.AD_OPS, 'AdOps', 'product.adopsName').asMultiple(),
  PRODUCT_ACCOUNT_MANAGER: new Filter(FILTERS_KEYS.PRODUCT_ACCOUNT_MANAGER, 'Account Manager', 'product.accountManager')
    .asMultiple()
    .setOptions(clientId => params => getAccountManagerFilterOptions(clientId, params))
    .getItemBy(getFilterByType),
  ANALYTICS_PRODUCT_NAME: new Filter(FILTERS_KEYS.PRODUCT_NAME, 'Product Name', 'product.name'),
  PRODUCT_ACCOUNT_NAME: new Filter(FILTERS_KEYS.PRODUCT_ACCOUNT_NAME, 'Account', 'product.accountName').asMultiple(),
  PRODUCT_LINE_ITEM_ID: new Filter(FILTERS_KEYS.PRODUCT_LINE_ITEM_ID, 'SF Line Item ID', 'product.id'),
  PRODUCT_NAME: new Filter(FILTERS_KEYS.PRODUCT_NAME, 'Name', 'product.name'),
  PRODUCT_OFFICE: new Filter(FILTERS_KEYS.PRODUCT_OFFICE, 'Office', 'product.office')
    .asMultiple()
    .setOptions(clientId => params => getOfficeFilterOptions(clientId, params))
    .getItemBy(getFilterByType),
  PRODUCT_OWNER_NAME: new Filter(
    FILTERS_KEYS.PRODUCT_OWNER_NAME,
    'Opportunity Owner',
    'product.ownerName'
  ).asMultiple(),
  PRODUCT_RATE: new Filter(FILTERS_KEYS.PRODUCT_RATE, 'Rate', 'product.rate')
    .asMultiple()
    .setOptions(clientId => params => getRateFilterOptions(clientId, params))
    .getItemBy(getFilterByType),
  PRODUCT_KPI: new Filter(FILTERS_KEYS.PRODUCT_KPI, 'KPI', 'product.kpi')
    .asMultiple()
    .setOptions(clientId => params => getKpiFilterOptions(clientId, params))
    .getItemBy(getFilterByType),
  PRODUCT_REPORT_ID: new Filter(FILTERS_KEYS.PRODUCT_REPORT_ID, 'Platform Reporting ID', 'product.reportId'),
  PRODUCT_FORMAT: new Filter(FILTERS_KEYS.PRODUCT_FORMAT, 'Format', 'product.format')
    .asMultiple()
    .setOptions(clientId => params => getFormatFilterOptions(clientId, params))
    .getItemBy(getFilterByType),
  PRODUCT_RECORDTYPE: new Filter(FILTERS_KEYS.PRODUCT_RECORDTYPE, 'Opportunity Record Type', 'product.recordTypeName')
    .asMultiple()
    .setOptions(clientId => params => getRecordTypeFilterOptions(clientId, params))
    .getItemBy(getFilterByType),
  PRODUCT_OWNER: new Filter(FILTERS_KEYS.PRODUCT_OWNER, 'Opportunity Owner', 'product.owner')
    .asMultiple()
    .setOptions(clientId => params => getOwnerFilterOptions(clientId, params))
    .getItemBy(getFilterByType),
  PRODUCT_ADOPS: new Filter(FILTERS_KEYS.PRODUCT_ADOPS, 'Adops', 'product.adops')
    .asMultiple()
    .setOptions(clientId => params => getAdOpsFilterOptions(clientId, params))
    .getItemBy(getFilterByType),
  PRODUCT_TAG: new Filter(FILTERS_KEYS.PRODUCT_TAG, 'Tags', 'product.tag', 'name', 'value').asMultiple().setOptions([
    { name: 'IAS', value: 'IAS' },
    { name: 'MOAS', value: 'MOAS' },
  ]),
  CAMPAIGN_NAME: new Filter(FILTERS_KEYS.CAMPAIGN_NAME, 'Campaign Name', 'campaign.name'),
  CAMPAIGN_GROUP_NAME: new Filter(FILTERS_KEYS.CAMPAIGN_GROUP_NAME, 'Name', 'campaignGroup.name'),
  CAMPAIGN_GROUP_STATUS: new Filter(
    FILTERS_KEYS.CAMPAIGN_GROUP_STATUS,
    'Name',
    'campaignGroup.status',
    'name',
    'value'
  ).setOptions([
    { name: 'Active', value: 'ACTIVE' },
    { name: 'Archived', value: 'ARCHIVED' },
    { name: 'Paused', value: 'PAUSED' },
  ]),
  CAMPAIGN_GROUP_SALESFORCE_ID: new Filter(
    FILTERS_KEYS.CAMPAIGN_GROUP_SALESFORCE_ID,
    'SF ID',
    'campaignGroup.salesForceId'
  ),
};
