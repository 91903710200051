import { thirdParty } from '@/services/modules/ThirdParty';
import { getAccountFilterOptions } from '@/services/modules/ThirdParty/filters/getAccountFilterOptions';
import { getAccountManagerFilterOptions } from '@/services/modules/ThirdParty/filters/getAccountManagerFilterOptions';
import { getAdOpsFilterOptions } from '@/services/modules/ThirdParty/filters/getAdOpsFilterOptions';
import { getFormatFilterOptions } from '@/services/modules/ThirdParty/filters/getFormatFilterOptions';
import { getOfficeFilterOptions } from '@/services/modules/ThirdParty/filters/getOfficeFilterOptions';
import { getOwnerFilterOptions } from '@/services/modules/ThirdParty/filters/getOwnerFilterOptions';
import { getRateFilterOptions } from '@/services/modules/ThirdParty/filters/getRateFilterOptions';
import { getRecordTypeFilterOptions } from '@/services/modules/ThirdParty/filters/getRecordTypeFilterOptions';
import { getKpiFilterOptions } from '@/services/modules/ThirdParty/filters/getKpiFilterOptions';

export const api = thirdParty;

export {
  getAccountFilterOptions,
  getAccountManagerFilterOptions,
  getAdOpsFilterOptions,
  getFormatFilterOptions,
  getOfficeFilterOptions,
  getOwnerFilterOptions,
  getRateFilterOptions,
  getRecordTypeFilterOptions,
  getKpiFilterOptions,
};
