import { SALESFORCE_OPPORTUNITIES_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { api } from '.';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = () => ({
  [SALESFORCE_OPPORTUNITIES_RESOURCE]: undefined,
});

export async function getSalesforceOpportunitiesShared(parser, params = new QueryParamsBuilder()) {
  if (!parser) throw new Error('Parser not provided');
  const partialUrl = api.createUrl(getResources());
  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(parser.parse(data), data.meta).build();
}
