import { getFilterByType } from '@/services/modules/ThirdParty/filters/getFilterByType';

/**
 *
 * @param {string} clientId
 * @param {import('@/model/shared/QueryParamsBuilder').default} params
 * @returns {Promise<any>}
 */
export function getKpiFilterOptions(clientId, params) {
  return getFilterByType(clientId, 'kpi', params);
}
